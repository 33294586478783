<div class="container-fluid">
  <section class="py-4 text-center">
    <div class="row py-lg-5">
      <div class="col-lg-6 col-md-8 mx-auto">
        <h1 class="fw-light" i18n="Concepts Title">Concepts</h1>
        <p class="lead text-body-secondary" i18n>
          Click the button below to upload your custom image for a DIY Paint By
          Numbers kit.
        </p>
        <p>
          <a
            [routerLink]="['/concepts/new']"
            class="btn btn-secondary my-2"
            i18n="Create new concept"
            >Create new</a
          >
          <br>
          <a
            href="https://www.numeroart.sk/ako-vybrat-vhodnu-fotku-na-obraz-podla-cisel/"
            class="link-secondary my-2 mt-4"
            target="_blank"
            rel="noopener noreferrer"
            i18n="How to choose a correct image"
          >How to choose a correct image</a>
        </p>
      </div>
    </div>
  </section>

  <section>
    <div class="pt-3" *ngIf="(isFiltered | async).filtered">
      <div class="row">
        <dt class="col-4 col-md-3 col-lg-2 pt-1">Filtered Product:</dt>
        <dd class="col-4 col-md-3 col-lg-2 pt-1">
          {{ (isFiltered | async).productName }}
        </dd>
        <button
          class="btn btn-secondary btn-sm col-4 col-md-2 col-lg-2"
          (click)="resetFilter()"
          i18n
        >
          Reset filter
        </button>
      </div>
    </div>

    <div class="album py-5 bg-body-tertiary">
      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
        <ng-container *ngIf="!(initialized | async)">
          <app-concept-placeholder
            *ngFor="let i of [].constructor(3)"
          ></app-concept-placeholder>
        </ng-container>
        <ng-container *ngIf="initialized | async">
          <app-concept
            class="d-flex align-self-stretch col-12 col-sm-6 col-md-6 col-lg-4"
            *ngFor="
              let concept of concepts
                | async
                | paginate : { itemsPerPage: 6, currentPage: currentPage }
            "
            [concept]="concept"
            [adminContext]="false"
          ></app-concept>
        </ng-container>
      </div>
    </div>
    <pagination-controls
      [previousLabel]="paginationControlPreviousStr"
      [nextLabel]="paginationControlNextStr"
      (pageChange)="pageChange($event)"
      class="concept-pagination"
    ></pagination-controls>
  </section>
</div>

<div *ngIf="url == '/'">
  <section class="py-5 text-center container">
    <div class="row py-lg-1">
      <div class="col-lg-8 col-md-8 mx-auto">
        <h1 class="fw-light" i18n>Welcome to the Numero Art designer</h1>
        <p class="lead text-body-secondary" i18n>
          Create your personalised Paint by Numbers kit easily by uploading your
          images to the concept creator page.
          This service is completely free to use. You can create as many Paint by Numbers images as you like without any
          cost. Enjoy the creative process and share your masterpieces with friends and family.
        </p>
        <p>
          <a
            [routerLink]="['/concepts']"
            class="btn btn-secondary my-2"
            i18n="concept button frontpage"
            >Concepts</a
          >
        </p>

        <ng-container *ngIf="!(auth.isAuthenticated$ | async)">
          <div class="shadow p-3 my-lg-5 bg-white rounded">
            <p class="lead text-muted" i18n>
              You are currently not logged in. To improve your user experience
              we recommend you to log in. Without logging in you can only create
              a single concept
            </p>
            <a
              (click)="auth.loginWithRedirect()"
              class="btn btn-sm btn-secondary"
              i18n
            >
              Log in
            </a>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 col-md-10 mx-auto">
        <img [src]="imgSourceUrl" class="img-fluid" />
      </div>
    </div>
  </section>
</div>
